import React, { useLayoutEffect } from "react"
import { graphql, PageProps, navigate } from "gatsby"

const Services: React.FC<PageProps<any>> = ({ data }) => {
  useLayoutEffect(() => {
    navigate(data.HOME.uri)
  }, [])
  return <></>
}

export const query = graphql`
  query ($locale: String!) {
    HOME: wpPage(
      locale: { locale: { eq: $locale } }
      template: { templateName: { eq: "Home" } }
    ) {
      uri
    }
  }
`

export default Services
